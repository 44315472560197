import React from 'react';
import BRAND_CONFIG from '../BRAND_CONFIG';
import AuthForm from '../components/AuthForm';

const {
    title, ctaText, ctaLoadingText, registerInstructions, registerImage, showTitle = true, showRegisterInstructions = false
} = BRAND_CONFIG.pages.register;

const RegisterPage = ({ location }) => {
    const params = new URLSearchParams(location?.search)
    const isAndroid = !!params.get('isAndroid')
    let displayInstructions = showRegisterInstructions
    let displayTitle = showTitle
    if (BRAND_CONFIG?.vendorUUID === 'c31f2558-e73a-49eb-8120-c3d388bfdc1d') {
        displayInstructions = isAndroid
        displayTitle = !isAndroid
    }
    return (
        <AuthForm
            title={title}
            ctaText={ctaText}
            ctaLoadingText={ctaLoadingText}
            submitType="register"
            instructions={registerInstructions}
            image={registerImage}
            showTitle={displayTitle}
            showRegisterInstructions={displayInstructions}
        />
    )
};

export default RegisterPage;
